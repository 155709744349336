import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public afs: AngularFirestore) { }

  /* Creating New Package  */
  createPackage(id: any, data: any) {
    return this.afs.doc('package/' + id).set(data);
  }

  getPackage(id: any) {
    return this.afs.doc('package/' + id).valueChanges();
  }
  deletePackage(id: any) {
    return this.afs.doc('package/' + id).delete();
  }

  getUser(id: any) {
    return this.afs.doc('user/' + id).valueChanges();
  }

  /* Employees/condidates List */
  getAllEmployees() {
    return this.afs.collection('user', ref => ref.where('userType','==','candidate')).snapshotChanges();
  }
  /* Employer List */
  getAllCompany() {
    return this.afs.collection('user', ref => ref.where('userType','==','employer')).snapshotChanges();
  }

  deleteUser(id){
    return this.afs.doc('user/'+id).delete();
  }
  /* Job List */
  getAllJobs() {
    return this.afs.collection('jobPost').snapshotChanges();
  }
  deleteJob(id){
    return this.afs.doc('jobPost/'+id).delete()
  }
  /* Business List */
  getAllbusiness() {
    return this.afs.collection('business').snapshotChanges();
  }
  getAllcomplains() {
    return this.afs.collection('complains').snapshotChanges();
  }
  // getAllbusiness() {
  //   return this.afs.collection('business').snapshotChanges();
  // }

  getUserByType(type){
    return this.afs.collection('user',ref => ref.where('userType','==',type)).snapshotChanges();
  }

  getEmployerProfile(){
    return this.afs.collection('employerProfile').snapshotChanges();
  }
  
  getEmployerProfileById(id){
    return this.afs.collection('employerProfile', ref => ref.where('userId','==',id)).snapshotChanges();
  }

  deleteCompany(id){
    return this.afs.doc('employerProfile/'+id).delete();
  }

  getEmployeeProfile(){
    return this.afs.collection('userProfile').snapshotChanges();
  }

  deleteEmployee(id){
    return this.afs.doc('userProfile/'+id).delete();
  }

  getAllPackages(){
    return this.afs.collection('packages').snapshotChanges();
  }

  getPackageById(id){
    return this.afs.doc('packages/'+id).valueChanges();
  }
  updatePackage(id,data){
    return this.afs.doc('packages/'+id).update(data);
  }

}
