import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-businesslist',
  templateUrl: './businesslist.component.html',
  styleUrls: ['./businesslist.component.css']
})
export class BusinesslistComponent implements OnInit {

  packages=[];

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
    this.api.getAllPackages()
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
        .subscribe( (res:Array<any>) =>{
          this.packages = res;
          this.packages.sort((a,b):any => {
            if(a.days<b.days){
              return -1;
            }
            else{
              return 1;
            }
          });
        });
  }

  edit(item){
    this.router.navigate(['edit-package',{
      id: item.did
    }]);
  }

}
