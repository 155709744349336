import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.css']
})
export class JoblistComponent implements OnInit {
  p = 1;
  searchText = '';

  jobList;
  jobs;
  job={
    job_title: ''
  };
  status = 'active';

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getAllJobs()
      .pipe(map(actions => actions.map(a =>{
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return {did, ...data};
      })))
        .subscribe((res: Array<any>) =>{
          this.jobList = res;
          this.jobs = this.jobList.filter(data => data.status === 'Active');
        });
  }

  activeJobs() {
    this.jobs = this.jobList.filter(data => data.status === 'Active');
    this.status = 'active';
  }
  finishJobs() {
    this.jobs = this.jobList.filter(data => data.status !== 'Active');
    this.status = 'completed';
  }

  featuredJobs(){
    this.jobs = this.jobList.filter(data => data.featured > '' || data.featured === true);
    this.status = 'featured';
  }

  delete(id){
    if(confirm('Do you want to delete this Job?')){
      this.api.deleteJob(id);
    }

  }
}
