import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { Http, RequestOptions, Headers } from '@angular/http';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  p = 1;
  searchText = '';
  userInfo;
  employees = {
    fullname: ''
  };
  userData = [];

  constructor(private api: ApiService, private http: Http) { }

  ngOnInit() {
    this.api.getEmployeeProfile()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe(res => {
        this.userInfo = res;
      });

    this.api.getUserByType('candidate')
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe(res => {
        this.userData = res;
      });
  }

  delete(item) {
    let id = item.userId;
    console.log(id);
    if (confirm(`Do you want to delete ${item.fullname}?`)) {
      let x = this.userData.filter(data => data.userId === id);
      if (x.length > 0) {
        this.api.deleteUser(x[0].did);
        const db = firebase.firestore();
        const query1 = db.collection('applyJob').where('candidateId', '==', id);
        query1.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.');
            return;
          }
          snapshot.forEach(a => {
            db.doc('applyJob/' + a.id).delete();
          })
        }, err => {
          console.log(err);
        });

        const query2 = db.collection('shortlistedJob').where('candidateId', '==', id);
        query2.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.');
            return;
          }
          snapshot.forEach(a => {
            db.doc('shortlistedJob/' + a.id).delete();
          })
        }, err => {
          console.log(err);
        });
      }
      this.api.deleteEmployee(item.did)
        .then(done => {
          this.deleteUser(id)
            .subscribe((res: any) => {
              if (res.statusText === 'OK' || res.status === 200) {
                alert('User deleted');
              }
            });
        });
    }
  }

  deleteUser(id) {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: myHeaders });
    //callrequest
    return this.http.post('https://us-central1-fireauth-55897.cloudfunctions.net/deleteUser/delete/', {
      uid: id,
      type: 'can'
    }, options);
  }

}
