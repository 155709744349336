import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'talentHunterAdmin';

  constructor() {
    if(!localStorage.getItem('language')){
      localStorage.setItem('language','en');
    }
  }
}
