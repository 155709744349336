import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-package',
  templateUrl: './edit-package.component.html',
  styleUrls: ['./edit-package.component.css']
})
export class EditPackageComponent implements OnInit {

  packageId;
  package;
  constructor(private route: ActivatedRoute, private api: ApiService, private router : Router) { }

  ngOnInit() {
    this.route.params.subscribe(res =>{
      this.packageId = res.id;

      this.api.getPackageById(this.packageId)
        .subscribe(data =>{
          this.package = data;
        });
    });
  }

  update(){
    this.api.updatePackage(this.packageId, this.package)
      .then(res =>{
        this.router.navigate(['businesslist']);
      })
  }

}
