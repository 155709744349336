import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';
import { Http, RequestOptions, Headers  } from '@angular/http';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-companylist',
  templateUrl: './companylist.component.html',
  styleUrls: ['./companylist.component.css']
})
export class CompanylistComponent implements OnInit {
  p = 1;
  searchText = '';
  users;
  userInfo;
  company={
    fullname: ''
  }

  constructor(private api: ApiService, private http: Http) { }

  ngOnInit() {
    this.api.getUserByType('employer')
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe(res => {
        this.users = res;
      });

    this.api.getEmployerProfile()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const did = a.payload.doc.id;
        return { did, ...data };
      })))
      .subscribe(res => {
        this.userInfo = res;
      });
  }

  delete(item){
    let id = item.userId;
    console.log(id);
    if(confirm(`Do you want to delete ${item.fullname}?`)){
      let x = this.users.filter(data => data.userId === id);
      if(x.length > 0){
        this.api.deleteUser(x[0].did);
      }
      const db = firebase.firestore();
      const query = db.collection('jobPost').where('userId', '==', id);
      query.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.');
            return;
          }
          snapshot.forEach(a => {
            db.doc('jobPost/'+a.id).delete();
          })
        }, err => {
          console.log(err);
        });

        const query1 = db.collection('applyJob').where('empId', '==', id);
        query1.get().then(snapshot => {
            if (snapshot.empty) {
              console.log('No matching documents.');
              return;
            }
            snapshot.forEach(a => {
              db.doc('applyJob/'+a.id).delete();
            })
          }, err => {
            console.log(err);
          });

          const query2 = db.collection('shortlistedJob').where('empId', '==', id);
          query2.get().then(snapshot => {
              if (snapshot.empty) {
                console.log('No matching documents.');
                return;
              }
              snapshot.forEach(a => {
                db.doc('shortlistedJob/'+a.id).delete();
              })
            }, err => {
              console.log(err);
            });
      this.api.deleteCompany(item.did)
        .then(done =>{
          this.deleteUser(id)
            .subscribe( (res:any) =>{
              if(res.statusText === 'OK' || res.status===200){
                alert('User deleted');
              }
            });
        });
    }
  }

  deleteUser(id){
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: myHeaders });
    //callrequest
    return this.http.post('https://us-central1-fireauth-55897.cloudfunctions.net/deleteUser/delete/',{
      uid: id,
      type: 'emp'
    }, options);
  }
}
